import withApi from "../../../Utils/ApiHelper";
import Loaderimg from "../../../Utils/Loader";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useErrorHandler from "../../CommonComponent/useErrorHandler";
import FormikSelect from "../../Formik/FormikSelect";
import FormikInput from "../../Formik/FormikInput";

const AddFuelAutomation = ({ isLoading, postData }) => {
  const { handleError } = useErrorHandler();
  const { id, siteName } = useParams();

  const handlePostData = async (values) => {
    try {
      const formData = new FormData();
      formData.append("manager_name", values.manager_name);
      formData.append("site_id", id);
      formData.append("bank_name", values.bank_name);
      formData.append("account_name", values.account_name);
      formData.append("account_no", values.account_no);
      formData.append("sort_code", values.sort_code);
      formData.append("sort_code", values.sort_code);

      const postDataUrl = "/site/bank-manager/add";
      const navigatePath = `/managebank/${id}`;

      await postData(postDataUrl, formData, navigatePath); // Set the submission state to false after the API call is completed
    } catch (error) {
      handleError(error); // Set the submission state to false if an error occurs
    }
  };

  const validationSchema = Yup.object({
    manager_name: Yup.string().required("Manager Name is required"),
    bank_name: Yup.string().required("Bank Name is required"),
    account_name: Yup.string().required("Account is required"),
    account_no: Yup.string().required("Account no is required"),
    sort_code: Yup.string().required("Sort code is required"),
  });

  const formik = useFormik({
    initialValues: {
      manager_name: "",
      bank_name: "",
      account_name: "",
      account_no: "",
      sort_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handlePostData(values);
    },
  });

  const frequencyOptions = [{ id: 1, name: "Daily" }];

  return (
    <>
      {isLoading ? <Loaderimg /> : null}
      <div>
        <div className="page-header">
          <div>
            <h1 className="page-title">Add Fuel Automation ({siteName})</h1>

            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item"
                linkAs={Link}
                linkProps={{ to: "/dashboard" }}
              >
                Dashboard
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item  breadcrumds"
                aria-current="page"
                linkAs={Link}
                linkProps={{ to: `/manage-fuel-automation/${id}` }}
              >
                Fuel Automation
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item active breadcrumds"
                aria-current="page"
              >
                Add Fuel Automation
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <Card>
              <Card.Header>
                <Card.Title as="h3">Add Fuel Automation</Card.Title>
              </Card.Header>
              <form onSubmit={formik.handleSubmit}>
                <Card.Body>
                  <Row>
                    <Col lg={4} md={6}>
                      <FormikSelect
                        formik={formik}
                        name="frequency"
                        label={"Frequency"}
                        options={frequencyOptions?.map((item) => ({
                          id: item?.value,
                          name: item?.name,
                        }))}
                        className="form-input"
                      />
                    </Col>

                    <Col lg={4} md={6}>
                      <FormikInput
                        formik={formik}
                        type="number"
                        label="Value"
                        name="value"
                        step="0.0001"
                      />
                    </Col>

                    <Col lg={4} md={6}>
                      <FormikInput
                        formik={formik}
                        type="time"
                        label="Time"
                        name="time"
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <div className="text-end my-5 text-end-small-screen">
                    <Link
                      type="submit"
                      className="btn btn-danger me-2 "
                      to={`/managebank/${id}`}
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withApi(AddFuelAutomation);
